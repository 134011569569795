import { Theme } from '@abi-labs-hexa/web-v2/core/theme';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import type { IMsalContext, MsalAuthenticationResult } from '@azure/msal-react';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { type FunctionComponent, lazy, memo, Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import '@abi-labs-hexa/web-v2/core/theme/index.css';

import { FullScreenLoader } from 'watchtower-ui/components/common/Spinner';
import router from 'watchtower-ui/routes';
import theme from 'watchtower-ui/theme';

const Unauthorized = lazy(() => import('watchtower-ui/pages/Unauthorized/Unauthorized'));

const queryClient = new QueryClient();

const AppInner = memo(() => (
  <QueryClientProvider client={queryClient}>
    <Suspense fallback={<FullScreenLoader />}>
      <RouterProvider router={router} />
    </Suspense>
  </QueryClientProvider>
));

const MsalApp = memo(() => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{}}
        loadingComponent={FullScreenLoader as unknown as FunctionComponent<IMsalContext>}
        errorComponent={Unauthorized as unknown as FunctionComponent<MsalAuthenticationResult>}
      >
        <AppInner />
      </MsalAuthenticationTemplate>
      {inProgress === InteractionStatus.None && !isAuthenticated && (
        <UnauthenticatedTemplate>
          <Unauthorized />
        </UnauthenticatedTemplate>
      )}
    </>
  );
});

const App = memo(() => (
  <Theme>
    <ChakraProvider theme={theme}>
      {import.meta.env.VITE_AUTOMATION_TOKEN ? <AppInner /> : <MsalApp />}
      <ToastContainer />
    </ChakraProvider>
  </Theme>
));
App.displayName = 'App';
export default App;
